import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const AshghalsFullWidth4 = () => {
  return (
    <section className="ash-full-width py-0">        
        <Container fluid>
            <Row>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/case-studies/ashghals-travel-app/ash-fulll-width-4.png" alt="Ashghals Travel App UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1440} height={1502}/>
                </Col>                
            </Row>
        </Container>
    </section>
  )
}

export default AshghalsFullWidth4
