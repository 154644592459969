import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import AshghalsMain from "../../components/case-studies/ashghals-travel-app/ashghalsMain"
import AshghalsInfo from "../../components/case-studies/ashghals-travel-app/ashghalsInfo"
import AshghalsFullWidth1 from "../../components/case-studies/ashghals-travel-app/ashghalsFullWidth1"
import AshghalsFullWidth2 from "../../components/case-studies/ashghals-travel-app/ashghalsFullWidth2"
import AshghalsTravelExp from "../../components/case-studies/ashghals-travel-app/ashghalsTravelExp"
import AshghalsDesign from "../../components/case-studies/ashghals-travel-app/ashghalsDesign"
import AshghalsFullWidth3 from "../../components/case-studies/ashghals-travel-app/ashghalsFullWidth3"
import AshghalsFullWidth4 from "../../components/case-studies/ashghals-travel-app/ashghalsFullWidth4"
import AshghalsMoreCs from "../../components/case-studies/ashghals-travel-app/ashghalsMoreCs"
import DirectContact from "../../components/global/directContact"

import csAshghalsFeaturedImage from "../../../static/case-studies/ashghals-travel-app/ashghals-featured-thumbnail.png"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/case-studies/ashghals-travel-app.scss"

export const Head = () => (
  <>
    <SeoHead
      ogImage={csAshghalsFeaturedImage}
      title="Ashghal TIS Case Study | UX/UI Design | Government"
      description="How we helped the Qatar Government design a Travel Information System"
    />
  </> 
)

const AshghalsTravelApp = () => {
    return (
      <Layout>
        <Seo
          title="Ashghal TIS Case Study | UX/UI Design | Government"
          description="How we helped the Qatar Government design a Travel Information System"
          bid="cs-ashghals-app"
          bclass="case-studies-page"
        ></Seo>
        <ScrollIndicationHeightZero />
        <AshghalsMain />
        <AshghalsInfo />
        <AshghalsFullWidth1 />
        <AshghalsFullWidth2 />
        <AshghalsTravelExp />
        <AshghalsDesign />
        <AshghalsFullWidth3 />
        <AshghalsFullWidth4 />
        <AshghalsMoreCs />
        <DirectContact />
      </Layout>
    )
}
export default AshghalsTravelApp
