import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const AshghalsMain = () => {
  return (
    <div className="cs-header d-flex align-items-end">
        <Container>
            <Row className="justify-content-center">
                <Col lg={5} md={10} className="position-relative">
                    <div className="hero-image text-center">
                        <StaticImage src="../../../images/case-studies/ashghals-travel-app/ashghals-hero-img.png" alt="Ashghals WowMakers Case study Header" placeholder="blurred" className="img-fluid" width={308} height={636}/>
                    </div>
                </Col>
            </Row>
        </Container>       
    </div>
  )
}

export default AshghalsMain
