import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const AshghalsDesign = () => {
  return (
    <section className="ash-design">
        <div className="ash-sub-content position-relative">
            <div className="travel-design-img text-right">
                <StaticImage src="../../../images/case-studies/ashghals-travel-app/ash-design-right.png" alt="Ashghals Travel App UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={663} height={292}/>
			</div>            
            <Container>
                <Row className="align-items-center">
                    <Col lg={5} className="text-center">
                        <StaticImage src="../../../images/case-studies/ashghals-travel-app/ash-design.png" alt="Ashghals Travel App UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={426} height={858}/>
                    </Col>
                    <Col lg={6} className="mt-5 mt-lg-0">
                        <h3 className="mb-3">Design that’s easy on the eye</h3>
						<p className="cs-sub-text">We went minimal with the design, with more focus on the usability feature. The app makes it easy for users to explore the most needed amenities and services by bringing them together on a single page.</p>                        
                    </Col>                
                </Row>
            </Container>
        </div>
    </section>
  )
}

export default AshghalsDesign
