import React from "react"
import AshghalsLogo from "../../icon/ashghalsLogo"
import { Col, Container, Row } from "react-bootstrap"

const AshghalsInfo = () => {
  return (
    <section className="cs-info position-relative">        
        <Container className="position-relative">
            <Row className="pb-md-5 pb-0">
                <Col lg={8}>
                    <h1 className="cs-title px-0 col-md-8 u-section mb-4 text-white">Travel assistance on the go.</h1>
                    <p className="cs-sub-text mb-4 text-white">Ashghal is a travel assistance platform that helps users move around the country with ease be it by foot, car, bike, or public transportation. The app offers extensive street maps, 360 interactive panoramic street views, and real-time traffic conditions.</p>
                    <Col md={8} className="details-mini d-flex justify-content-between px-0">
                        <div className="stats-info">
                            <p className="cs-sub-text st-title mb-0 text-white">Duration</p>
                            <p className="st-data mb-0 text-white">3 Months</p>
                        </div>
                        <div className="stats-info">
                            <p className="cs-sub-text st-title mb-0 text-white">Services</p>
                            <p className="st-data mb-0 text-white">UX/UI</p>
                        </div>
                        <div className="stats-info">
                            <p className="cs-sub-text st-title mb-0 text-white">Platform</p>
                            <p className="st-data mb-0 text-white">iOS, Android</p>
                        </div>												
                    </Col>                
                </Col>
                <Col lg={3} className="offset-lg-1 text-lg-end mt-lg-0 mt-md-5 mt-5">
                    <div className="position-relative">
                        <AshghalsLogo />
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default AshghalsInfo
