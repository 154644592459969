import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const AshghalsTravelExp = () => {
  return (
    <section className="ash-travel-exp pt-0">        
        <Container>
            <Row className="justify-content-center">
                <Col lg={6} className="text-center pb-lg-5 mb-lg-5 pb-0 mb-0">
                    <h2 className="sub-title">Delivering a hassle-free travel experience.</h2>
                </Col>                
            </Row>
        </Container>
        <div className="ash-sub-content mt-lg-5 pt-lg-5 mt-md-5 mt-0 pt-0 position-relative">
            <div className="travel-exp-img text-right">
                <StaticImage src="../../../images/case-studies/ashghals-travel-app/travel-exp-image.png" alt="Ashghals Travel App experience UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid" width={600} height={728}/>
			</div>
            <Container>
                <Row className="align-items-center">
                    <Col lg={5}>
                        <h3 className="mb-3">Save the route, or add to favorites.</h3>
						<p className="cs-sub-text">We figured out that it would be more logical to provide a feature where users can save their usual routes and places, or add them to favorites. This way, users can save the time they usually spend searching for locations or destinations.</p>
                    </Col>
                    <Col lg={4} className="text-center mt-4 mt-lg-0">
                        <StaticImage src="../../../images/case-studies/ashghals-travel-app/save-route.png" alt="Ashghals Travel App experience UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={336} height={679}/>
                    </Col>
                </Row>
            </Container>            
        </div>
    </section>
  )
}

export default AshghalsTravelExp
