import React from "react";

function AshghalsLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="251"
      height="139"
      fill="none"
      viewBox="0 0 251 139"
    >
      <path
        fill="#fff"
        d="M251 14.259h-10.761v39.218H251V14.259zM225.896 53.478h10.761V.004H82.469V10.7H225.9v3.564H82.47v10.692H225.9v3.564H82.47v10.695H225.9v3.56H53.783v-3.563H64.54V28.52H53.783v-3.564H64.54V14.264H43.026v28.512h-3.59V10.703h10.761V.007H28.683v53.457h197.213v.014zM25.097 53.46V0h-10.75v42.768H10.76V14.256H.003v39.208H25.1l-.003-.004zM250.997 7.132h-7.172V3.568h7.172V.004h-10.758V10.7h10.758V7.132zM250.993 64.152H0v10.692h250.993V64.152z"
      ></path>
      <path
        fill="#fff"
        d="M78.887.004H68.129v10.695h10.758V.004zM78.887 14.259H68.129V24.95h10.758V14.259zM64.54.004H53.787v10.695H64.54V.004zM78.887 28.516H68.129v10.692h10.758V28.516zM250.994 128.304V99.792H240.24v28.512h-3.589V85.536h-10.757v53.463h25.1v-10.695zM182.871 138.996h10.754v-53.46h-10.754v14.256h-3.589V85.536h-10.754v14.256h-14.343v28.512h-3.589v-32.07h14.343V85.54h-25.097v53.457h25.1v-28.509h3.589v28.512h10.754v-28.512h3.59l-.004 28.509zM211.553 99.795h-3.589v-3.564h3.589v3.564zM197.21 139h10.754v-28.512h3.586v28.512h10.757v-53.46h-14.343v-.041H197.21v24.976M125.502 99.792h-3.589V85.536h-93.23V96.23h82.469V139h10.761v-28.512h3.589v28.512h10.754V85.536h-10.754v14.256z"
      ></path>
      <path
        fill="#fff"
        d="M14.347 99.797h-3.59v-3.564h3.59v3.564zm14.343 0v24.944h68.13v3.561H25.103V85.534H.004v53.463h10.76v-28.512h3.583v28.512h93.226v-24.951H39.44v-3.557h68.133V99.793H28.69v.004z"
      ></path>
    </svg>
  );
}

export default AshghalsLogo;